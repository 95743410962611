/* eslint-disable import/no-unresolved */
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Spinner } from 'metroplex';
import { idRef } from '@gooddata/sdk-model';
import { useDashboardLoader } from '@gooddata/sdk-ui-loaders';
import { DashboardStoreAccessorRepository } from '@gooddata/sdk-ui-dashboard';
import SelectedDashboardAtom from 'core/framework/recoil/atoms/selected-dashboard-atom';
import VenueFilterPlugin from 'components/Dashboard/Plugins/VenueFilterPlugin';

import 'iframe-resizer/js/iframeResizer.contentWindow.min';
import './Dashboard.scss';

const ExtraPlugins = {
    factory: () => new VenueFilterPlugin(),
};

/**
 * DashboardComponent is responsible for loading and rendering a GoodData dashboard.
 *
 * @param {Object} venueIds - The venue IDs to be passed to the dashboard.
 *
 * @returns {JSX.Element} The rendered DashboardComponent.
 */
const DashboardComponent = venueIds => {
    const selectedDashboard = useRecoilValue(SelectedDashboardAtom);

    const config = {
        isReadOnly: true,
        hideSaveAsNewButton: true,
    };

    const dashboardRef = idRef(selectedDashboard.id);

    const { status, result, error } = useDashboardLoader({
        dashboard: dashboardRef,
        // indicate that only local plugins should be used
        loadingMode: 'staticOnly',
        extraPlugins: ExtraPlugins,
    });

    if (status === 'loading' || status === 'pending' || error) {
        return <div className="dashboard--loading"><Spinner modifier="spinner--large" /></div>;
    }

    // When embedded within an iframe, we should let the parent component know it's loaded
    if (window.location.pathname.includes('/iframe')) {
        window.parent.postMessage('dashboard-loaded', '*');
    }
    // the loader result returns the component to be used and also props that need to be passed to it
    const { DashboardComponent: Dashboard, props: dashboardProps } = result;

    return (
        <div data-iframe-height>
            <Dashboard
                {...dashboardProps}
                config={config}
                venueIds={venueIds}
                onStateChange={DashboardStoreAccessorRepository.getOnChangeHandlerForDashboard(dashboardRef)}
                reloadAfter={3000}
            />
        </div>
    );
};

export default DashboardComponent;
